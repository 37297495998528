h1 {
    font-size: 3rem;
    color: #1a304d;
    font-weight:600;
    font-family:Montserrat,sans-serif}
    @media screen and (max-width: 1510px) {
        
        div.MuiTypography-h4{font-size: 2rem!important;}
    }
    @media screen and (max-width: 1260px) {
        
        div.MuiTypography-h4{font-size: 1.6rem!important;}
    }

    @media screen and (max-width: 1000px) {
        div.MuiBox-root{margin:2rem}
        h1.MuiTypography-h1 {font-size: 2rem;}
        h2.MuiTypography-h2{font-size: 1.75rem;}
        h3.MuiTypography-h3{font-size: 1.2rem;}
        div.MuiTypography-h4{font-size: 1.5rem!important;}
    }
    @media screen and (max-width: 800px) {
        
        div.MuiTypography-h4{font-size: 1.2rem!important;}
    }
    @media screen and (max-width: 600px) {
        div.MuiBox-root{margin:1em 0 0 0}
        h1.MuiTypography-h1 {font-size: 1.5rem;}
        h2.MuiTypography-h2{font-size: 1.25rem;}
        h3.MuiTypography-h3{font-size: 1rem;}
        div.MuiTypography-h4{font-size: 1.2rem!important;}
    }
    @media screen and (max-width: 580px) {
        div.MuiContainer-root{text-align:left}
    div.MuiContainer-root a.MuiButtonBase-root {width:100%;display:block;border-top:1px solid #ccc;padding-left:1.5em}
    div.MuiToolbar-root>div.MuiContainer-root>a.MuiButtonBase-root:first-of-type{padding-left:0.5em}
    }